import styled from "styled-components";
import { BP, Rem } from "../../../commons/Theme";

export const StyledBottomBeeSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto ${Rem(64)} auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 ${Rem(20)};

  text-align: center;

  img {
    display: block;
    width: 100%;

    margin-bottom: ${Rem(16)};
  }

  button {
    width: 100%;
  }

  @media (${BP.tablet}) {
    max-width: 60%;

    img {
      margin: 0 auto;
      margin-bottom: ${Rem(24)};
    }

    button {
      width: ${Rem(320)};
    }
  }
`;

export const StyledBottomBeeSectionTitle = styled.h2`
  font-family: 'Vollkorn';
  font-weight: 700;
  font-size: ${Rem(32)};
  line-height: ${Rem(40)};

  margin-bottom: ${Rem(8)};

  @media (${BP.tablet}) {
    font-size: ${Rem(48)};
    line-height: ${Rem(67)};

    margin-bottom: ${Rem(26)};
  }
`;

export const StyledBottomBeeSectionDescription = styled.p`
  font-weight: 700;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};

  letter-spacing: 3px;

  margin-bottom: ${Rem(8)};

  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
    line-height: ${Rem(30)};

    margin-bottom: ${Rem(26)};
  }
`;
