import { Button } from "@3beehivetech/components";
import React from "react";
import {
  StyledBottomBeeSectionContainer,
  StyledBottomBeeSectionDescription,
  StyledBottomBeeSectionTitle,
} from "./style";

export default ({ onCtaClick, content }) => {
  return (
    <StyledBottomBeeSectionContainer>
      <StyledBottomBeeSectionTitle
        dangerouslySetInnerHTML={{ __html: content.title }}
      />

      <StyledBottomBeeSectionDescription
        dangerouslySetInnerHTML={{ __html: content.description }}
      />

      <video width="640" height="360" autoPlay muted loop playsinline controls={false}>
        <source src={content.video.url} type="video/mp4" />
      </video>

      <Button onClick={onCtaClick}>{content.ctaLabel}</Button>
    </StyledBottomBeeSectionContainer>
  );
};
